<template>
  <!-- !PAGE CONTENT! -->
  <div class="w3-main" style="margin-left:450px">

    <!-- Push down content on small screens -->
    <div class="w3-hide-large" style="margin-top:83px"></div>

    <!-- Top header -->
    <header class="w3-container w3-xlarge">

      <p class="w3-right">

      </p>
    </header>

    <!--

  <div class="w3-row-padding w3-margin-bottom">
    
    
    <div class="w3-quarter w3-padding">
      <div class="w3-container w3-light-gray w3-padding-16">
        <div class="w3-clear"></div>
        <h4>ABC Apartments</h4>
        <h6 class = "w3-padding"><i class = "w3-text-red fa fa-map-marker"></i> Philadelphia, PA</h6>
        <div>
        <i class="fa fa-home w3-medium w3-padding "> 10/100</i>
        <i class="fa fa-building w3-medium  w3-padding "> 10/10</i>
        <i class="fa fa-wifi w3-medium w3-padding  "> 1/2</i>
        
        </div>
      </div>
    </div>


    <div class="w3-quarter w3-padding">
      <div class="w3-container  w3-light-gray  w3-padding-16">
      <div class="w3-center">
        <h4>Total Water Usage</h4>
      </div>
        <div class="light-gray w3-center w3-round-xxlarge">
            <div class="w3-container w3-red w3-center w3-round-xxlarge" style="width:75%">5000 Gallons</div>
        </div>
        
    </div>
    </div>



    <div class="w3-quarter w3-padding">
      <div class="w3-container  w3-light-gray w3-padding-16">
        <div class="w3-left"><i class="fa fa-share-alt w3-xxxlarge"></i></div>
          <div class="w3-right">
              <h3>40% Less</h3>
          </div>
        <div class="w3-clear"></div>
          <h4>Similar Apartments</h4>
            <div class="w3-light-grey w3-round-xxlarge">
              <div class="w3-container w3-green w3-center w3-round-xxlarge" style="width:25%">25%</div>
            </div>
      <br>
          <div class="w3-light-grey w3-round-xxlarge">
            <div class="w3-container w3-yellow w3-center w3-round-xxlarge" style="width:65%">65%</div>
          </div>
        </div>
    </div>
    
    
    <div class="w3-quarter w3-padding">
      <div class="w3-container  w3-light-gray w3-text-black w3-padding-16">
        <div class="w3-left"><i class="fa fa-users w3-xxxlarge"></i></div>
        <div class="w3-right">
          <h3>10% less</h3>
        </div>
        <div class="w3-clear"></div>
        <h4>Global Comparison</h4>
        <div class="w3-light-grey w3-round-xxlarge">
          <div class="w3-container w3-green w3-center w3-round-xxlarge" style="width:25%">25%</div>
        </div>
      <br>
      <div class="w3-light-grey w3-round-xxlarge">
        <div class="w3-container w3-yellow w3-center w3-round-xxlarge" style="width:35%">35%</div>
      </div>
      </div>
    </div>
  </div>

  <div class="w3-panel  w3-padding-64">
    <div class="w3-row-padding" style="margin:0 -16px">
      <div class="w3-half  w3-padding-32 w3-white">
        <h5>Leak Alerts</h5>
        <table class="w3-table w3-striped w3-white">
          
          <tr>
            <th>Apt</th>
            <th>24 Hr</th>
            <th>30 Day</th>
          </tr>
          <tr>
            <td> <i class = "fa fa-building"></i> 101 </td>
            <td><i>30 Gal</i></td>
            <td><i>3000 Gal</i></td>
          </tr>
          <tr>
            <td> <i class = "fa fa-building"></i> 102 </td>
            
            <td><i>45 Gal</i></td>
            <td><i>3103 Gal</i></td>
          </tr>
          <tr>
            <td> <i class = "fa fa-building"></i> 103 </td>
            
           
            <td><i>56 Gal</i></td>
            <td><i>3200 Gal</i></td>
          </tr>
          <tr>
            <td> <i class = "fa fa-building"></i> 104 </td>
            
            <td><i>3 Gal</i></td>
            
            <td><i>121 Gal</i></td>
          </tr>
          <tr>
            <td> <i class = "fa fa-building"></i> 105 </td>
            <td><i>13 Gal</i></td>
            <td><i>436 Gal</i></td>
          </tr>
        </table>
      </div>

      <div class="w3-half w3-padding-32">
        <h5>Device Alerts</h5>
        <table class="w3-table w3-striped w3-white">
          <tr>
            <td><i class="fa fa-wifi"></i> Gateway</td>
            <td><i>Offline</i></td>
            <td><i>4 Days</i></td>
            <td><i>High Priority</i></td>
          </tr>

          <tr>
            
            <td><i class="fa fa-thermometer-0"></i> Thermostat</td>
            <td><i>Offline</i></td>
            <td><i>14 Days</i></td>
            <td><i>Low Priority</i></td>
          </tr>
          <tr>
            <td><i class = "fa fa-fire"></i> Heater</td>
            <td><i>Offline</i></td>
            <td><i>1 Day</i></td>
            <td><i>High Priority</i></td>
          </tr>
          
          
        </table>
      </div>
    </div>

      <div class="w3-row-padding">
      <div class="w3-full ">
        <h5>No Consume Alerts</h5>
        <table class="w3-table w3-striped w3-white">
          <tr>
            <td><i class = "fa fa-building"></i> 109</td>
            <td><i>no consumtion </i></td>
            <td><i>31 Days</i></td>
          </tr>
           <tr>
            <td><i class = "fa fa-building"></i> 119</td>
            <td><i>no consumtion </i></td>
            <td><i>31 Days</i></td>
          </tr>
           <tr>
            <td><i class = "fa fa-building"></i> 129</td>
            <td><i>no consumtion </i></td>
            <td><i>31 Days</i></td>
          </tr>
           <tr>
            <td><i class = "fa fa-building"></i> 139</td>
            <td><i>no consumtion </i></td>
            <td><i>31 Days</i></td>
          </tr>
           <tr>
            <td><i class = "fa fa-building"></i> 149</td>
            <td><i>no consumtion </i></td>
            <td><i>31 Days</i></td>
          </tr>

          
        </table>
      </div>
    </div>
  </div>
  <hr>

  <div class="w3-container w3-text-grey" id="jeans">
    
    
      
      <p>3 Sites </p>
    
    

  </div>


    <div class="w3-row w3-grayscale">
      <div class="w3-col l3 s6">

        <div class="w3-card-4">

          <p class="w3-padding">10 shrewberry park<br><span class="w3-badge w3-green">-</span><b class="w3-text-black">
              Online</b></p>

        </div>


      </div>

      <div class="w3-col l3 s6">
        <div class="w3-container">
          <div class="w3-display-container">
            <img src="/w3images/jeans2.jpg" style="width:100%">
            <span class="w3-tag w3-display-topleft">New</span>
            <div class="w3-display-middle w3-display-hover">
              <button class="w3-button w3-black">View now <i class="fa fa-shopping-cart"></i></button>
            </div>
          </div>
          <p>Test Site<br><b>Online</b></p>
        </div>
        <div class="w3-container">
          <img src="/w3images/jeans3.jpg" style="width:100%">
          <p>Test Site<br><b>Online</b></p>
        </div>
      </div>

      <div class="w3-col l3 s6">
        <div class="w3-container">
          <img src="/w3images/jeans3.jpg" style="width:100%">
          <p>Test Site<br><b>Online</b></p>
        </div>
        <div class="w3-container">
          <div class="w3-display-container">
            <img src="/w3images/jeans4.jpg" style="width:100%">
            <span class="w3-tag w3-display-topleft">View Site</span>
            <div class="w3-display-middle w3-display-hover">
              <button class="w3-button w3-black">View Site<i class="fa fa-shopping-cart"></i></button>
            </div>
          </div>
          <p><br><b class="w3-text-red">Offline</b></p>
        </div>
      </div>

      <div class="w3-col l3 s6">
        <div class="w3-container">
          <img src="/w3images/jeans4.jpg" style="width:100%">
          <p>Test Site<br><b>Online</b></p>
        </div>
        <div class="w3-container">
          <img src="/w3images/jeans1.jpg" style="width:100%">
          <p>Test Site<br><b>Online</b></p>
        </div>
      </div>
    </div>
    -->

  </div>
</template>

<script>


export default {
  created() {
    this.$router.push('/sites');
  }

}
</script>
