<template>
  <nav v-show="storeData.isDivVisible" class="w3-sidebar w3-bar-block w3-white w3-top" style="" id="mySidebar">
    <div class="w3-container w3-display-container w3-padding-16">
      <i @click="toggleDivVisibility" class="fa-thin fa-x w3-large w3-hover-text-green w3-button w3-display-topright"></i>
      <img src="../../assets/logo_transparent.png" alt="H2o Logo" class='w3-animate-left' />
      <h6>Site Manager</h6>
    </div>
    <div class="w3-padding-32 w3-large w3-text-grey">
      <!--
      <router-link to="/" class="w3-bar-item w3-button w3-padding">Overview</router-link>

      <router-link to="/sites" class="w3-bar-item w3-button w3-padding" @click="toggleSitesVisibility">
        <h4>Sites</h4>
      </router-link>
-->
      <div v-if="areSitesVisible">

        <VueMultiselect class="w3-light-grey w3-margin-bottom" v-if="sitesData != null" v-model="value"
          :options="sitesData" :close-on-select="true" :clear-on-select="false" placeholder="Choose A Site" label="name"
          track-by="name" />

        <p v-else>Loading site list <i class='fa-thin fa-spinner-third w3-spin'></i></p>



        <TreeBrowser v-if="storeData.tree[0] != null" :nodes="storeData.tree" />

      </div>

      <!--
      <router-link to="/users" class="w3-bar-item w3-button w3-padding">Users</router-link>

      <router-link to="/reports" class="w3-bar-item w3-button w3-padding">Reports</router-link>

      <router-link to="/realtime" class="w3-bar-item w3-button w3-padding">Realtime</router-link>

      <router-link to="/alerts" class="w3-bar-item w3-button w3-padding">Alerts</router-link>

      <router-link to="/devicectrl" class="w3-bar-item w3-button w3-padding">Devicectrl</router-link>

    </div>
    <a href="#footer" class="w3-bar-item w3-button w3-padding">Contact</a>
    <a href="javascript:void(0)" class="w3-bar-item w3-button w3-padding">Newsletter</a>
    <a href="#footer" class="w3-bar-item w3-button w3-padding">Subscribe</a>
  -->
    </div>
  </nav>





  <router-view></router-view>
</template>

<script>
import TreeBrowser from "./siteTree.vue";
import rootData from "../../root.json";
import { store } from '../../helpers/state.js'
import VueMultiselect from 'vue-multiselect'
import Command from "../../helpers/commands";

export default {

  data() {
    return {
      root: rootData,

      areSitesVisible: true,
      storeData: store,
      sites: null,
      sitesData: null,
      base: process.env.VUE_APP_HOSTBASE,
      wsBase: process.env.VUE_APP_WSBASE,
      value: null,

    };
  },

  watch: {

    value: async function (newVal, oldVal) {
      console.log('sidebar newVal', newVal);
      console.log('sidebar oldVal', oldVal);

      this.storeData.tree = [];
      //this.storeData.tree.push(newVal);
      if(newVal!=null){

        console.log('newVal', newVal);
        
        if(this.storeData.property.target_hostname_list !=null){
          console.log('target hostname list', this.storeData.property.target_hostname_list);
          var command = new Command(JSON.stringify({ "target_property_id": `${this.storeData.property.id}`, "target_hostname_list": `${this.storeData.property.target_hostname_list}`, "command_token": "sitemanager@6cXZ3xrAi3dRxMqDvJY75pMMwEizIDs68r57l4pP", "command": "stop_stream" }));
          command.send();
        }
        
        var requestUrl = `${this.base}/api/sites/${newVal.id}/structures?user_id=${this.storeData.commandConfig.user_id}&sk=${this.storeData.commandConfig.sk}`;
        console.log(requestUrl);
       
        var newestChildren = await fetch(requestUrl)
        var data = await newestChildren.json();

        if(data.error_message == 'no_records'){
          console.log('no records');
          this.storeData.noBuildings = true;
        }else{

          this.storeData.noBuildings = false;
          console.log('newest children data', data);
          var newestChildrenData = data;  
          newVal.children = newestChildrenData;
          
        }

        this.storeData.tree.push(newVal);
        console.log(this.storeData.tree);
        this.storeData.siteViewType.siteType = 'propertyDetails'; 
      }
    },

    // watching the root (base of the tree ) for changes
    root: {
      deep: true,
      handler: function (newRoot) {
        console.log('newroot', newRoot);
      },
    },

    // watching the route for changes
    '$route': {
      handler: async function (to, from) {

        console.log('route changed', to, from);
        //console.log(this.$route.query);

        if (this.$route.query.id && this.$route.query.sk && this.$route.query.email) {
          // console.log('query params');
          //console.log(this.$route.query, this.$route.query.id, this.$route.query.sk, this.$route.query.email);

          // Add the query parameter to the state
          this.storeData.commandConfig.sk = this.$route.query.sk;
          this.storeData.commandConfig.user_id = this.$route.query.id;
          this.storeData.commandConfig.email = this.$route.query.email;

          // Save the query parameter to local storage
          localStorage.setItem('sk', this.$route.query.sk);
          localStorage.setItem('user_id', this.$route.query.id);
          localStorage.setItem('email', this.$route.query.email);

          await this.getSites();

        } else {
          //console.log('no query params');

          if (localStorage.getItem('sk')) {

            this.storeData.commandConfig.sk = localStorage.getItem('sk');
            this.storeData.commandConfig.user_id = localStorage.getItem('user_id');
            this.storeData.commandConfig.email = localStorage.getItem('email');
            //console.log('got auth from local storage', this.storeData.commandConfig.sk, this.storeData.commandConfig.user_id, this.storeData.commandConfig.email);
            //fetch the users sites
            await this.getSites();


          } else {
            // console.log('no auth found from any sources');
            alert('Please login again');
            window.location.href = 'https://portal.h2odegree.net';

          }
        }
      }
    }



  },

  methods: {

    heartbeat() {
      //console.log('ws heartbeat');
      clearTimeout(this.pingTimeout);

      this.pingTimeout = setTimeout(() => {
        this.terminate();
      }, 3000);
    },

    recieveExpandedEvent(eventArr) {
      console.log('expanded event recieved' + eventArr);

    },

    async getSites() {

      try {
        //fetch the users sites
        console.log('fetching sites');
        this.sites = await fetch(`${this.base}/api/sites?user_id=${this.storeData.commandConfig.user_id}&sk=${this.storeData.commandConfig.sk}&email=${this.storeData.commandConfig.email}`);
        this.sitesData = await this.sites.json();
        console.log('sites data', this.sitesData);
        if (this.sitesData.length <= 0 || this.sitesData == null || this.sitesData.error == true) {

          if(this.sitesData.error == true){
            console.log('error', this.sitesData.error_message);
            alert(this.sitesData.error_message);
            return; 
          }

          console.log('No sites found for this user');
          this.storeData.noproperties = true;

        } else {

          this.storeData.noproperties = false;
          this.storeData.user.show_advanced = this.sitesData[0].show_advanced;

        }


      } catch (err) {

        console.log('there was some error fetching the users sites', err);

      }

    },

    toggleDivVisibility() {
      this.storeData.isDivVisible = !this.storeData.isDivVisible;
      console.log('div visible', this.storeData.isDivVisible);
    },

    async toggleSitesVisibility() {

      this.areSitesVisible = !this.areSitesVisible;
   
    },



  },




  created: async function () {


    
    // Create a WebSocket connection
    console.log('Connecting to websocket server');
    this.storeData.connection = new WebSocket(this.wsBase + "/ws");
    //this.storeData.connection = new WebSocket(wsBase);

    //when we get any web socket message sent to us. 
    this.storeData.connection.onmessage = (event) => {
      // console.log('onmessage function');
      // console.log('recieved message => ', JSON.parse(event.data));
      var message = event.data.toString();
      var jsonMessage = JSON.parse(message);

      console.log('message', message);
      

      // if the message is an auth message
      if (jsonMessage.authState) {
        console.log('auth state', jsonMessage.authState);
        this.storeData.deviceInfo.commandAuth.authState = jsonMessage.authState;
      } else {

        console.log('message eui', jsonMessage.header.common.serial_number, "state eui", this.storeData.deviceInfo.meta2.eui, jsonMessage.header.common.serial_number == this.storeData.deviceInfo.meta2.eui );
        console.log('jsonmessage sensor data', jsonMessage.sensor_data)
        console.log('jsonmessage', jsonMessage)

        // add the message to the live data queue if the live data stream is on
        if(this.storeData.liveDataStream==true){
          jsonMessage.timestamp = new Date().toLocaleString();  
          this.storeData.liveDataQueue.push(jsonMessage);
        }else{

        // handle for device specific messages
        if(jsonMessage.header.common.serial_number == this.storeData.deviceInfo.meta2.eui ){
            

           
          
            // Parse the given date string into a Date object
            // Build the "last seen" string
            var lastSeenString = '0' + "D " + '0' + 'H ' + "0" + "M " + '0' + "S";

            this.storeData.deviceInfo.meta1.insert_date = lastSeenString;
            

            if(JSON.stringify(jsonMessage.sensor_data) != JSON.stringify({'none': 'none'})){ 
              
              console.log('Got Sensor Data ');
              this.storeData.deviceInfo.sensor_data = jsonMessage.sensor_data;

               // need to check if the command was expecting a command_response or sensor_data before decalaring it a success 
              if (this.storeData.deviceInfo.commandAuth.isSpecial == false ){
                this.storeData.deviceInfo.commandAuth.authState = 'Command Success';
                console.log('regular command success');
                setTimeout(() => {
                  console.log('resetting auth state');
                  this.storeData.deviceInfo.commandAuth.authState = null;
                }, 4000);
              }
                
             
            }else{
              console.log('Got Command Response');
             
              this.storeData.deviceInfo.command_response = jsonMessage.command_response;

              // need to check if the command was expecting a command_response or sensor_data before decalaring it a success
              if (this.storeData.deviceInfo.commandAuth.isSpecial == true) {
                this.storeData.deviceInfo.commandAuth.authState = 'Command Success';
                console.log('special command success');
                setTimeout(() => {
                  console.log('resetting auth state');
                  this.storeData.deviceInfo.commandAuth.authState = null;
                }, 4000);
                
              }


            }
            
      }
    }
      }


    };

    //when the connection to the server websocket in completed
    this.storeData.connection.onopen = function (err) {

      this.heartbeat;

      if (err) {

        // console.log(err);

      } else {

        console.log('Connected to websocket server');

      }


    }

    this.storeData.connection.onclose = (event) => {
      console.log('websocket onclose function', event);
      alert('Connection to server lost. Refreshing the page...');
      location.reload();
    }

  },


  components: {
    TreeBrowser,
    VueMultiselect,
  },

}


</script>


<style scoped>
nav {
  z-index: 4;
  max-width: 300px
}

@media screen and (max-width: 600px) {
  nav {
    max-width: 100%;
  }

}

img {
  max-width: 70%;
}

.w3-container {
  font-family: 'Quicksand', sans-serif;
}

h6 {
  font-family: 'Quicksand', sans-serif;
}
/* Hide scrollbar for Chrome, Safari and Opera */
nav::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
nav {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>


  
 