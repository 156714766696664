import { createRouter, createWebHistory } from 'vue-router'
import overView from '../views/overView.vue'

const routes = [
  {
    path: '/',
    name: 'overView',
    component: overView
  },
  {
    path: '/users',
    name: 'Users',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/usersView.vue')  
  },
  {
    path: '/reports',
    name: 'Reports',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ()=> import('../views/reportsView.vue')  
  },
  {
    path: '/realtime',
    name: 'Realtime',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/realtimeView.vue')  
  },
  {
    path: '/sites',
    name: 'Sites',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/sitesView.vue')   
  },
  {
    path: '/alerts',
    name: 'Alerts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/alertsView.vue')
      
  },
  {
    path: '/devicectrl',
    name: 'Devicectrl',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/devicectlView.vue')
      
  }


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
