// Import the classes from the other file
import Command from './commands.js';


export default class Router {

    constructor(propId, commandConfig, deviceInfo, authInfo) {

        // build the command structure for the thermostat based on the whole config
        // TODO: this needs some error handling for bad and unexpected input
        this.commandStructure = {

            //auth section
            user_id: parseInt(authInfo.user_id),
            sk: authInfo.sk,
            user_email: authInfo.email,

            target_unique_id: parseInt(commandConfig.unique_id),
            target_property_id: propId,
            target_dev_eui: commandConfig.eui,

            target_hostname_list: [commandConfig.last_hostname],
            qr: commandConfig.qr,
            command_token: "sitemanager@6cXZ3xrAi3dRxMqDvJY75pMMwEizIDs68r57l4pP",
            unit_code: deviceInfo.meta2.unit_code,

        }

        this.deviceInfo = deviceInfo;


        console.log('new router details', this.commandStructure)
        console.log('new router device info', this.deviceInfo);

    }




    currentCommandSelection = '';


    // perhaps put this in some sort of database or config file
    commands = [

        {
            name: "ping",
            title: "Refresh",
            allowedQr: [27, 21, 26, 1001,],
            payloads: [
                {
                    payloadName: 'jitter',
                    payloadTitle: 'Jitter',
                    /*
                    options: [
                      
                      {
                        optionName: 'true',
                        optionTitle: 'True',
                        allowedQr: [92, 105, 36, 33, 34, 35, 67],
                      },
                      
                      {
                        optionName: 'false',
                        optionTitle: 'False',
                        allowedQr: [92, 105, 36, 33, 34, 35, 67],
                      }
                      
                    ]
                    */
                }

            ],
        },
    ]

    chartOptions = {
        responsive: true,
        tension: 0,
        scales: {
            x: {
                type: 'time',
            },
            y: {
                ticks: {
                    callback: function (value) {
                        return value.toFixed(0) + 'Gal'; // Add the Gallons symbol to the label
                    }
                }
            }
        },
        plugins: {
            legend: {
                postion: 'bottom',
            },
        }
    }

    async sendCommand(commandName, formData) {

        // show what the command config looks like before we send it
        this.commandStructure.target_device_type = this.deviceInfo.meta1.header_common_device_type;
        console.log('command config before commands', this.commandStructure);


        Object.keys(formData).forEach(key => {
            JSON.stringify(key);
        });






        console.log('commandName', commandName);
        console.log('formData', formData);






        // assign the params to vars so we can validate them and use them

        console.log(`Command =>  ${commandName}`);

        this.commandStructure.downlink_payload = formData;
        this.commandStructure.command = commandName;





        // make it json for the backend processing 
        var stringCommand = JSON.stringify(this.commandStructure);

        console.log('stringCommand', stringCommand);

        //send it to the commands class (websocket connection) 
        var command = new Command(stringCommand);
        command.send();


    }


}
